<template>
  <div class="login-page" :style="backgroundStyle">
    <el-card class="box">
      <img
        :src="logo"
        class="logo"
        :alt="system.name">

      <h1 :style="taglineGradient">{{ system.name }}</h1>
      <h3>Reset password</h3>

      <div class="form" v-if="!success">
        <ms-input
          v-model="form.username"
          placeholder="User name/email"
          @enter="login()" />

        <div v-if="error" class="error-text">{{ error }}</div>
        <div v-else>
          If your username is found in our system, you will receive an email with instructions on how to reset your password.
        </div>
      </div>
      <div class="form" v-else>
        <div class="success-text">{{ success }}</div>
      </div>

      <el-button
        v-if="!success"
        :style="buttonStyle"
        class="w-full"
        size="large"
        round
        @click="resetPw()">
        Reset Password
      </el-button>

      <div class="create-an-account">
        <el-button type="text" class="link" @click="goLogin">
          Go to login
        </el-button>
      </div>
    </el-card>

     <auth-footer />
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import AuthFooter from '../../components/auth/AuthFooter'

export default {
  components: {
    AuthFooter
  },

  data () {
    return {
      form: {
        username: null,
        password: null,
        mode: 'customerApp'
      },
      version: null,
      error: null,
      success: null,
      year: (new Date()).getFullYear()
    }
  },

  computed: {
    taglineGradient () {
      const colorsLeft = this.theme ? this.theme.loginTaglineGradientLeft : '#1E2D4D'
      const colorsRight = this.theme ? this.theme.loginTaglineGradientRight : '#405984'

      return {
        background: `-webkit-linear-gradient(${colorsLeft}, ${colorsRight})`,
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent'
      }
    },

    buttonStyle () {
      const colorsLeft = this.theme ? this.theme.loginButtonGradientLeft : '#1E2D4D'
      const colorsRight = this.theme ? this.theme.loginButtonGradientRight : '#405984'
      return {
        background: `linear-gradient(86deg, ${colorsLeft}, ${colorsLeft} 20%, ${colorsRight})`,
        color: this.$colors.white
      }
    },

    backgroundStyle () {
      const colorsLeft = this.theme ? this.theme.loginBackgroundGradientLeft : '#1E2D4D'
      const colorsRight = this.theme ? this.theme.loginBackgroundGradientRight : '#405984'
      return {
        background: `linear-gradient(180deg, ${colorsLeft} 0%, ${colorsRight} 100%)`
      }
    },

    theme () {
      return this.$store.getters['app/theme']
    },

    logo () {
      return this.system.logo ? this.$image('system', this.system.logo) : null
    },
    system () {
      return this.$store.state.app.system
    }
  },

  methods: {
    async resetPw () {
      this.error = null
      this.loading = true
      try {
        await this.$store.dispatch('app/sendPwReset', this.form)
        this.success = 'Please check your email for instructions on how to change your password'
      } catch (error) {
        this.error = 'Wrong email or password. Please try again.'
      } finally {
        this.loading = false
      }
    },
    goLogin() {
      this.$router.push({name: "Login"})
    },
  },

  validations () {
    return {
      form: {
        username: { required, email },
        password: { required }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login-page {
  width: 100%;
  min-height: 100vh;
  padding: 47px 15px 25px 15px;
  box-sizing: border-box;
  text-align: center;

  .box {
    width: 100%;
    left: 15px;
    top: 152px;
    background: $bg_dark;
    border-radius: 18px;
    padding-top: 20px;
  }
  .logo {
    width: 137px;
    height: auto;
  }
  h1 {
    display: block;
    margin-top: 10px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }
  h3 {
    margin-top: 17px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: $text;
  }
  .form {
    margin-top: 72px;
    margin-bottom: 38px;
  }
  .create-an-account {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #616F7B;
    margin-top: 36px;
    margin-bottom: 21px;
    .error-text {
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      text-align: center;
      color: $danger;
    }
  }
  .link {
    color: $link;
  }
}
</style>
