<template>
  <div class="footer-section" :style="loginFooterTextColor">
    <p>Not Signed Up Yet? <router-link :to="{name: 'Register'}" :style="loginFooterTextLinkColor">Register now</router-link> to get started.</p>
    <br />
<!--     <p>Need to activate? <router-link :to="{name: 'Activation'}" :style="loginFooterTextLinkColor">Activate now</router-link> to get started.</p> -->
    <template v-if="false">
      <p>{{ system.loginPageLine1 }}</p>
      <p>{{ system.loginPageLine2 }}</p>
      <p>{{ system.loginPageLine3 }}</p>
    </template>
  </div>
</template>

<script>
export default {
  computed: {
    loginFooterTextColor () {
      return {
        color: this.theme?.loginFooterTextColor || this.$colors.white
      }
    },

    loginFooterTextLinkColor () {
      return {
        color: this.theme?.loginFooterTextLinkColor || this.$colors.link
      }
    },

    theme () {
      return this.$store.getters['app/theme']
    },

    system () {
      return this.$store.state.app.system
    },

    version () {
      return this.$store.state.app.version
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-section {
  margin-top: 30px;
  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: $white;
  }
  a {
    color: $white;
    text-decoration: underline;
  }
}
</style>
